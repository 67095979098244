<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item>个人赛</el-breadcrumb-item>
      <el-breadcrumb-item>全国总决赛(有组别)</el-breadcrumb-item>
      <el-breadcrumb-item>国赛报道签到</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 签到筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>签到筛选</span>
        </el-col>
      </el-row>
      <el-form :model="queryForm" label-width="80px" ref="queryFormRef">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="选手姓名" prop="name">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入选手姓名"
                @change="getDataList()"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入选手手机号"
                @change="getDataList()"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年级" prop="grade">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.grade"
                clearable
              >
                <el-option
                  v-for="item in dict_grade"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赛区" prop="districtId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.districtId"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签到状态" prop="reported">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.reported"
                clearable
              >
                <el-option
                  v-for="item in dict_reported"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="领物料状态"
              prop="received"
              label-width="100px"
            >
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.received"
                clearable
              >
                <el-option
                  v-for="item in dict_received"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="getDataList"
              >查询</el-button
            >
          </el-col>
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- 签到列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title">签到列表</div>
          <el-switch
            v-model="autoRefreshEnable"
            active-text="自动刷新(5s)"
            inactive-text="手动刷新"
          >
          </el-switch>
        </el-col>
        <el-col :span="12">
          <div class="div_algin_right">
            <el-button type="success" @click="showQr()"
              >报道签到二维码</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
      >
        <el-table-column type="index"></el-table-column>
        <el-table-column label="赛区" prop="district_name"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="国赛号码牌" prop="userName">
          <template slot-scope="scope">
            <span style="color: #ff0000; font-size: 20px;"
            >{{ scope.row.nationalnum }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="性别"
          prop="sex"
          :formatter="genderFormatter"
        ></el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column label="年级" prop="grade"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="签到状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.reported === '1'"
              >已签到</el-tag
            >
            <el-tag type="warning" v-else>未签到</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="领取物料状态" prop="received">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.received === '1'"
              >已领取</el-tag
            >
            <el-tag type="warning" v-else>未领取</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="签到时间" prop="reported_time" width="150px">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="showEditDialog(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 修改的dialog -->
    <el-dialog title="修改" :visible.sync="editDialogVisible" width="30%">
      <el-form :model="reportSignInData">
        <el-form-item label="头像:">
          <el-image
            style="width: 100px; height: 100px"
            :src="reportSignInData.avater"
          ></el-image>
        </el-form-item>
        <el-form-item label="姓名:">
          <div>{{ reportSignInData.name }}</div>
        </el-form-item>
        <el-form-item label="手机:">
          <div>{{ reportSignInData.phone }}</div>
        </el-form-item>
        <el-form-item label="学校:">
          <div>{{ reportSignInData.schoolName }}</div>
        </el-form-item>
        <el-form-item label="年级:">
          <div>{{ reportSignInData.grade }}</div>
        </el-form-item>
        <el-form-item label="国赛号码牌:">
          <div style="font-size: 60px; color: red">
            {{ reportSignInData.nationalNum }}
          </div>
        </el-form-item>
        <el-form-item label="签到状态:">
          <el-switch
            v-model="reportSignInData.reported"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="领物料状态:">
          <el-switch
            v-model="reportSignInData.received"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="备注信息:">
          <el-input
            v-model="reportSignInData.remark"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editSignInData">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 显示签到二维码的dialog -->
    <el-dialog
      title="签到二维码"
      :visible.sync="showQrDialogVisible"
      width="30%"
    >
      <img :src="qrImgUrl" style="width: 100%" />
    </el-dialog>
  </div>
</template>

<script>
import {
  queryDistrictPage, getReportSignInList, getReportSignInInfo, editReportSignInInfo
} from '@/http/api'
export default {
  data () {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 20,
        activityType: this.$chnEngStatusCode.activityType,
        name: '',
        phone: '',
        reported: '',
        received: '',
        grade: '',
        districtId: ''
      },
      total: 0,
      dict_grade: this.$userInfo.getGradeData(),
      dict_reported: this.$userInfo.dict_signIn(),
      dict_received: this.$userInfo.dict_received(),
      dict_district: [],
      dataList: [],
      showQrDialogVisible: false,
      qrImgUrl: '',
      editDialogVisible: false,
      reportSignInData: {
      },
      autoRefreshEnable: false
    }
  },
  created () {
    this.getDistrictList()
  },
  beforeDestroy () {
    this.autoRefreshEnable = false
  },
  methods: {
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getDataList()
        this.autoRefresh()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    getDataList () {
      getReportSignInList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    autoRefresh () {
      setInterval(() => {
        if (this.autoRefreshEnable) {
          this.getDataList(true)
        }
      }, 5000)
    },
    genderFormatter (data) {
      if (data.sex === '0') {
        return '女'
      }
      return '男'
    },
    timeStampFormatter (row, col, cell) {
      return this.$xcUtils.timestamp2Date(parseInt(cell))
    },
    showQr () {
      this.qrImgUrl = 'https://cdn.spbcn.org/spbcnapp/QRCode/national_final_reportSign.png'
      this.showQrDialogVisible = true
    },
    async showEditDialog (data) {
      getReportSignInInfo({ id: data.userId }).then((res) => {
        this.reportSignInData = res.data
        this.editDialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    editSignInData () {
      this.reportSignInData.activityType = this.$chnEngStatusCode.activityType
      editReportSignInInfo(this.reportSignInData).then((res) => {
        this.$message.success('修改成功')
        this.editDialogVisible = false
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  text-align: center;
}

.div_algin_right {
  text-align: right;
}
</style>
